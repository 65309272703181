import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58')
];

export const server_loads = [0,2,5,7,9,10,11,12];

export const dictionary = {
		"/": [~13],
		"/auth/change-provider/[provider=provider]": [~14],
		"/dashboard/(dashboard)": [15,[2]],
		"/dashboard/actions": [16,[2]],
		"/dashboard/actions/[actionId]/[sessionId]": [17,[2]],
		"/dashboard/assist": [18,[2]],
		"/dashboard/billing": [~19,[2,3]],
		"/dashboard/billing/success": [20,[2,3]],
		"/dashboard/calendar-log": [22,[2]],
		"/dashboard/calendar": [21,[2]],
		"/dashboard/contacts": [23,[2,4]],
		"/dashboard/contacts/[id]": [24,[2,4]],
		"/dashboard/emails": [25,[2]],
		"/dashboard/emails/[[folderExec]]/[folder]": [26,[2,5]],
		"/dashboard/schedule": [27,[2]],
		"/dashboard/schedule/new": [29,[2]],
		"/dashboard/schedule/nuevo": [30,[2]],
		"/dashboard/schedule/[scheduleId]": [28,[2]],
		"/dashboard/settings": [~31,[2]],
		"/dashboard/settings/calendar/[execId]": [32,[2]],
		"/dashboard/tasks": [33,[2]],
		"/dashboard/tasks/new": [36,[2]],
		"/dashboard/tasks/[agendaId]": [34,[2]],
		"/dashboard/tasks/[agendaId]/view": [35,[2]],
		"/dashboard/team": [37,[2]],
		"/dashboard/team/add": [38,[2]],
		"/errors/mailbox-inactive": [~39,[6]],
		"/errors/missing-scopes/[provider=provider]": [40,[6]],
		"/internal": [41,[7]],
		"/internal/organizations": [~42,[7]],
		"/internal/organizations/[orgId]": [~43,[7]],
		"/internal/users": [~44,[7]],
		"/internal/users/[execId]": [~45,[7]],
		"/invite/[shortCode]": [46,[,9],[8]],
		"/invite/[shortCode]/success": [47,[,9],[8]],
		"/join-org/[orgId]/[inviteId]/(welcome)": [48,[10]],
		"/join-org/[orgId]/[inviteId]/permissions": [49,[10]],
		"/login": [50],
		"/welcome/(welcome)": [51,[11]],
		"/welcome/(with-org)/calendars": [52,[11,12]],
		"/welcome/(with-org)/done": [53,[11,12]],
		"/welcome/(with-org)/emails": [54,[11,12]],
		"/welcome/organization": [58,[11]],
		"/welcome/(with-org)/services": [55,[11,12]],
		"/welcome/(with-org)/team": [56,[11,12]],
		"/welcome/(with-org)/upgrade": [~57,[11,12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';